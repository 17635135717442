@charset "utf-8";

// Our variables
$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;

// Mark stuff stolen from https://github.com/Chalarangelo/mini.css/blob/master/src/mini/_contextual.scss
$mark-back-color:        #0277bd !default;       // Background color for <mark>
$mark-fore-color:        #fafafa !default;       // Text color for <mark>

$mark-font-size:         0.95em !default;        // Font size for <mark>
$mark-line-height:       1em !default;           // Line height for <mark>
$mark-inline-block-name: 'inline-block' !default;// Class name for inline-block <mark>
$mark-back-color-var:         '--mark-back-color' !default;
$mark-fore-color-var:         '--mark-fore-color' !default;


// $universal-margin:        0.5rem !default;      // Universal margin for the most elements
$universal-padding:       0.5rem !default;      // Universal padding for the most elements
$universal-border-radius: 0.125rem !default;    // Universal border-radius for most elements
$universal-box-shadow:    none !default;        // Universal box-shadow for most elements
// $universal-margin-var:          '--universal-margin' !default;
$universal-padding-var:         '--universal-padding' !default;
$universal-border-radius-var:   '--universal-border-radius' !default;
$universal-box-shadow-var:      '--universal-box-shadow' !default;

div.wrapper {
    text-align: justify;
}
img.me {
    margin-left: 20px;
}

// Default styling for mark. Use mixins for alternate styles.
:root {
  #{$universal-padding-var}: $universal-padding;
  #{$universal-border-radius-var}: $universal-border-radius;
  @if $universal-box-shadow != none {
    #{$universal-box-shadow-var}: $universal-box-shadow;
  }
  // #{$mark-back-color-var}: $mark-back-color;
  // #{$mark-fore-color-var}: $mark-fore-color;
}
// :root {
//  #{$universal-margin-var}: $universal-margin;
// }
mark {
  color: $mark-fore-color;
  font-size: $mark-font-size;
  line-height: $mark-line-height;
  border-radius: var(#{$universal-border-radius-var});
  padding: calc(var(#{$universal-padding-var}) / 4) calc(var(#{$universal-padding-var}) / 2);
  @if $universal-box-shadow != none {
    box-shadow: var(#{$universal-box-shadow-var});
  }
  &.#{$mark-inline-block-name} {
    display: inline-block;
    // This is hardcoded, as we want inline-block <mark> elements to be styled as normal pieces of text, instead of look small and weird.
    font-size: 1em;
    // Line height is reset to the normal line-height from `core`. Also hardcoded for same reasons.
    line-height: $base-line-height;
    padding: calc(var(#{$universal-padding-var}) / 2) var(#{$universal-padding-var});
  }
}

mark.journal {
  background: #7A5A7E;
}

mark.conference {
  // background: #a0332f;
  background: #468B7A;
}

mark.workshop {
  background: #6B7A8B;
  // color: black;
}

mark.preprint {
  background: #4F82B1;
  // color: black;
}

mark.bookchapter {
  background: #8C5C4A;
}

mark.thesis {
  // background: #93a864;
  background: #5E734A;
}

ol.publications,ol.theses { 
    // The combination of flex and column-reverse is how the ordering is changed.
    display: flex;
    flex-direction: column-reverse;
}

li {
    flex: 0 0 auto;
}

span.highlight {
   font-variant-caps: small-caps;
  background: #F56974;
  color: White;
  font-size: smaller;
  padding: 2px;
}

span.note {
  // font-variant-caps: small-caps;
 background: LightGray;
 color: White;
 font-size: smaller;
 padding: 2px;
}

u {
text-decoration-style: dotted;
}

// For the sidebar navigation
// div.wrapper {max-width:960px;margin-left:5%;}
// div.post-content > p {width:65%;max-width:640;}
nav.toc-fixed {
    position:fixed;
    top:50px;
    left:5%;
    max-width:320px
}
nav.toc-fixed ul {
    list-style:none;
    padding-left:0;
    margin-left:0;
	border-left: 1px solid rgb(0, 140, 186);
}
nav.toc-fixed ul >li > a {
    color: inherit;
    padding-left: 5px;
}
nav.toc-fixed ul > li > a:active {
	border-left: 1px solid rgb(0, 140, 186);
	color: rgb(0, 140, 186) !important;
}

@import "minima";
@import "hint"

// Minima also includes a mixin for defining media queries.
// Use media queries like this:
// @include media-query($on-laptop) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }


// Import partials from the `minima` theme.
// @import "ace";
// @import "starving-artist";
